
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&family=Roboto:wght@300;400;500;700&display=swap');
:root {
  --bs-font-sans-serif: "Roboto", sans-serif;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.75rem;
  font-weight: 800;
  line-height: 1.2;
  color: #373f50;
  font-family: "Nunito Sans", sans-serif;
}

#root{
  height: 100% !important;
}

.explore-1
{
   text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.navFixedNFT{
  position: fixed !important;
  background-color: white !important;
  animation: navbar-show .25s;
    box-shadow: 0 0.25rem 0.5625rem -0.0625rem rgb(0 0 0 / 3%), 0 0.275rem 1.25rem -0.0625rem rgb(0 0 0 / 5%);
    z-index: 1030;

}

.modal {
  position: fixed;
  top: 20%;
  left: 0;
  z-index: 1055;
  display: block;
  width: 100%;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

hr.solid {
  border-top: 2px solid #bbb;
  margin: 5px 0px;
}

.blink_me {
  animation: blinker 1s linear 5;
}

.headShake_me {
  animation: headShake 3s linear infinite;
}



.balanceError {
  color:#ca5340;
}

.paragraph{
  color:#ca5340;
  cursor: pointer;
}
.paragraph:hover{
  color:#7c2f23;
  cursor: pointer;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes headShake {
  30% {
    opacity: 1;
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}


.story {
	background:white;
	padding:10px 5px;
}
.story .story-item {
	height: 160px;
    border-radius: 15px;
    background: #3e7fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    color: white;
	position:relative;
	font-size:13px;	
  margin-left: 0.3rem;
}  
.story .story-item:before {
   content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    opacity: .2;
    border-radius: 15px;
  } 
.story .story-item span {
	position:absolute;bottom:0;
	padding:10px 5px; 
  font-family: sans-serif;
}
.story .owl-stage {
    right: 15px;
}

.roundedSuscription {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width:32px;
  height:32px;
  margin:5px;
  position:absolute;
  border-radius:50%;
 border:2px solid #3877e1;
 
}
input[type=range] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 2px #A6A6A6;
  background: #fe696a;
  border-radius: 4px;
  border: 2px solid #F27B7F;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 2px #A6A6A6;
  border: 2px solid #F27B7F;
  height: 30px;
  width: 30px;
  border-radius: 0px;
  background: #fe696a;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: #fe696a;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 2px #A6A6A6;
  background: #fe696a;
  border-radius: 4px;
  border: 2px solid #F27B7F;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 2px #A6A6A6;
  border: 2px solid #F27B7F;
  height: 30px;
  width: 30px;
  border-radius: 0px;
  background: #fe696a;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: #fe696a;
  border: 2px solid #F27B7F;
  border-radius: 8px;
  box-shadow: 1px 1px 2px #A6A6A6;
}
input[type=range]::-ms-fill-upper {
  background: #fe696a;
  border: 2px solid #F27B7F;
  border-radius: 8px;
  box-shadow: 1px 1px 2px #A6A6A6;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 2px #A6A6A6;
  border: 2px solid #F27B7F;
  height: 30px;
  width: 30px;
  border-radius: 0px;
  background: #fe696a;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: #fe696a;
}
input[type=range]:focus::-ms-fill-upper {
  background: #fe696a;
}

.tooltipNFT {
  position: relative;
  display: inline-block;
}
.tooltipNFT .tiptext {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #2b3445;
  border-radius: 0.25rem;
  font-family: "Rubik", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-size: 0.75rem;
  opacity: .9;

}
.tooltipNFT .tiptext::after {
  content: "";
  position: absolute;
  border-width: 5px;
  border-style: solid;
}
.tooltipNFT:hover .tiptext {
  visibility: visible;
}





.tooltipNFT.top .tiptext{
  margin-left: -60px;
  bottom: 150%;
  left: 50%;
}
.tooltipNFT.top .tiptext::after{
  margin-left: -5px;
  top: 100%;
  left: 50%;
  border-color: #2E2E2E transparent transparent transparent;
}
.tooltipNFT.bottom .tiptext{
  margin-left: -4.4rem;
  top: 115%;
  left: 50%;
}
.tooltipNFT.bottom .tiptext::after{
  margin-left: -5px;
  bottom: 100%;
  left: 50%;
  border-color: transparent transparent #2E2E2E transparent;
}
.tooltipNFT.left .tiptext{
  top: -5px;
  right: 110%;
}
.tooltipNFT.left .tiptext::after{
  margin-top: -5px;
  top: 50%;
  left: 100%;
  border-color: transparent transparent transparent #2E2E2E;
}
.tooltipNFT.right .tiptext{
  top: -5px;
  left: 110%;
}
.tooltipNFT.right .tiptext::after{
  margin-top: -5px;
  top: 50%;
  right: 100%;
  border-color: transparent #2E2E2E transparent transparent;
}

.checkboxBig{
  width: 2em;
  height: 2em;
}
.center-block{
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.MaxHeightWidthFeed{
  max-width: 35rem;
  max-height: 625px;
}

.MaxHeightWidthPreview{
  max-width: 400px;
  max-height: 400px;
}
.EmojiPickerReact{
  z-index: 1;
}

.text-left{
  text-align: left;
}

.heart {
  font-size: 15px;
  color: #e00;
  animation: beat .45s infinite alternate;
  transform-origin: center;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
.heart {
  color: #e00;
  animation: beat .25s infinite alternate;
}
@keyframes beat{
	to { transform: scale(1.5); }
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 10;
}
@media only screen and (min-device-width : 120px) and (max-device-width : 769px) {
  .MaxHeightWidthFeed{
    max-width: 100%;
    max-height: auto;
  }
  .MaxHeightWidthPreview{
    max-width: 100%;
    max-height: auto;
  }
}
@media only screen and (min-device-width : 120px) and (max-device-width : 320px) {
  .modal-dialog{
    width: 310px;
  }

}

@media (max-width: 500px) {
  .modal{
    top:10%
  }
  .divMedia{
    flex: 0 0 auto;
    width: 50%;
  }
}



.badge-wrap {
  position: relative;
  display: inline-block;
  margin: 10px;
}

.badge-with-number {
  position: absolute;
  color: #fff;
  background-color: #f5424e;
  font-size: 9px;
  padding: 3px 10px;
  border-radius: 8px;
  top: -2px;
  right: -15px;
}


.nomargin{
  margin: 0px
}

.colorPrimary{
  color: #f5424e
}

.NFTimage {
  background: #ffffff;
  max-width: 100%;
  height: auto;
  width: 100%;
  vertical-align: middle;
  max-height: 30vh;
  min-height: 30vh;
  object-fit: cover;
  object-position: 50% 50%;
}
.NFTDetail {
  background: #ffffff;
  max-width: 100%;
  height: auto;
  width: 100%;
  vertical-align: middle;
  max-height: 65vh;
  min-height: 0vh;
  object-fit: contain;
  object-position: 50% 50%;
  
}
.buttonPlay{
    border: medium none;
    color: #fff;
    display: block;
    font-size: 18px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 33%;
    text-align: center;
    font-size: 10vh;

}
.nocolor{
  color:#2E2E2E
}

.offcanvas{
  z-index: 1040;
}
.editor-class {
  background-color:lightgray;
  padding: 1rem;
  border: 1px solid #ccc;
}


.btn-extension {
  background-image: url(https://www.rednft.redlineblockchain.com/logo.png);
  color: green;
}

.btn-extensionURL {
  background-image: url(https://upload.wikimedia.org/wikipedia/commons/thumb/e/e1/Google_Chrome_icon_%28February_2022%29.svg/2048px-Google_Chrome_icon_%28February_2022%29.svg.png);
  color: white;
}

.cover-image-skeleton {
  width: 100%;
  height: 70vh;
  background: linear-gradient(90deg, #EEEEEE 25%, #DEDEDE 50%, #EEEEEE 75%);
  background-size: 200% 100%;
  animation: loading 2s infinite ease-in-out;
  border-radius: 8px;
}
.ol-faq{
  font-size: 0.875rem !important;
  color: #7d879c !important;
  text-align: justify;
  padding-left: 1rem;
}

.faq {
  color: #2e2e2e !important;
  font-size: 0.875rem !important;
  min-height: 330px;
  .number-faq{
    font-size: 2rem;
    border: 3px solid #fe696a;
    color:#fe696a;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  
  .number-faq:hover{
   background-color: #fe696a;
   color: white;
  }
  ol {
    text-align: left;
    padding-left: 1.5rem;
  }
  ol li ol li{
    list-style: inside;
  }
}
.search-faq{
  color: #f5424e;
    cursor: pointer;
}
@keyframes loading {
  0% {
      background-position: 200% 0;
  }
  100% {
      background-position: -200% 0;
  }
}

.EditorToolbar__root___3_Aqz{
  text-align: left;
}


.RichTextEditor__root___2QXK-{
  font-family: "Roboto", sans-serif !important;
}

.faq-question{
  background: #f0e9e97d;
    border-radius: 10px;
}

.picture-container {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9; /* Proporción 16:9 */
  overflow: hidden;
  background-color: #f0f0f0; /* Color de fondo para verificar el contenedor */
}

.picture-content {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container without distortion */
  max-height: 200px;
}

.btn-market {
  display: inline-block;
  padding: 0.375rem 0.875rem;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.2s ease-in-out;
  border-radius: 0.4375rem;
  background-position: center left 0.75rem;
  background-color: #2b3445;
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
  text-decoration: none;
}

.btn-market-inverse {
  display: inline-block;
  padding: 0.375rem 0.875rem;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, box-shadow 0.2s ease-in-out;
  border-radius: 0.4375rem;
  background-position: center left 0.75rem;
  background-color: transparent;
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
  text-decoration: none;
}
